// pages/about/Vision.tsx
import React from 'react';
import Header from '@/components/ui/header';

const Pricing = () => {
  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-6">Aanmelding en vergoedingen</h1>
        <p className='text-gray-700 font-bold'>
          Rechtstreeks aanmelden bij het Trauma, Verlies en Angst Praktijk ‘t Gooi jeugd (0-tot 18) en (jong-)volwassenen en het gezin
        </p>
        <div className='pt-2'>
          <span className='font-semibold underline'>Cliënten:</span> Indien u een vraag heeft voor onderzoek en/of behandeling of begeleiding bij Trauma, Verlies en Angst- en dwangklachten, kunt u als cliënt of ouder van cliënt rechtstreeks bij ons aanmelden (verwijzing nodig voor vergoeding van de zorg).
        </div>
        <div>
          <span className='font-semibold underline'>Verwijzers</span> kunnen altijd contact met ons op te nemen voor overleg over een mogelijke aanmelding.
        </div>
        <div className='pt-2 '> 
          <span className='font-semibold flex mr-2'>
            Voor aanmeldingen kunt u een mail sturen naar:
          <a 
            href={`mailto:info@traumaverliesangstpraktijkgooi.nl`}
            className="pl-2 hover:opacity-80 transition-opacity font-normal  hover:text-main-blue"
          >info@traumaverliesangstpraktijkgooi.nl.</a>
          </span>
            Vermeld altijd een telefoonnummer. U wordt dan door ons teruggebeld om meer over uw vraag te horen. Daarna bespreken we de vraag in het team en laten u weten of wij uw vraag kunnen aannemen. Wij nemen dan zo spoedig mogelijk contact met u op. U kunt bij ons aanmelden met vragen gerelateerd aan trauma, verlies of angst- en dwangklachten. 
        </div>
        <div className='pt-3'>
          <div className='font-semibold'>
            Zorgkosten Jeugd: Zelf betalen of vergoeding door gemeente
          </div>
          Als u de zorg zelf wil betalen kunt u kinderen zonder verwijzing aanmelden. De
          behandeling van kinderen die staan ingeschreven buiten de regio Gooi- en
          vechtstreek, wordt ook door u zelf betaald. <br/>
          De bekostiging van de <span className='font-semibold'>vergoede zorg van de jeugd-ggz</span> (tot 18 jaar) valt sinds
          2015  de onder de gemeente. Alexandra Ridders heeft een contract met Regio Gooi
          & Vechtstreek. U kunt direct aanmelden en hoeft daarvoor geen contact op te nemen
          met de gemeente. Wel is er een verwijzing nodig van de huisarts, een medisch
          specialist, of jeugdarts nodig.
        </div>
        <div className='pt-3'>
          <div className='font-semibold'>
          De verwijzing voor vergoede zorg moet voldoen aan de eisen van de overheid (zie hierna).
          </div>
          Wij melden uw kind vervolgens aan bij de gemeente waar uw kind staat
          ingeschreven. We regelen de facturatie rechtstreeks met de regio Gooi &amp;
          Vechtstreek: <span className='text-sm'>Huizen, Hilversum, Gooise Meren (te weten Naarden, Bussum en Muiden), Laren en
          Blaricum. Weesp en Wijdemeren (te weten Ankeveen, Kortenhoef, &#39;s-Graveland, Loosdrecht,
          Nederhorst den Berg en Breukeleveen).</span>
          <div className='font-semibold pt-1'>
            Verwijzing door de huisarts, medisch specialist (kinderarts), gemeente
            zorgloket en/of een jeugdarts van de GGD:
          </div>
          De verwijzing dient te voldoen aan de volgende voorwaarden:
          <ul>
            <li>
              1. De verwijzing is gedateerd en gericht aan het Lindehuis
            </li>
            <li>
              2. De verwijzing is voorafgaand aan de behandeling afgegeven
            </li>
            <li>
              3. De verwijzing bevat de volledige NAW- gegevens van de verwijzend arts, inclusief de AGB-code van de verwijzer
            </li>
            <li>
              4. De verwijzing bevat de (elektronische) handtekening van de verwijzend arts
            </li>
            <li>
              5. De verwijzing bevat de volledige NAW-gegevens van de verzekerde
            </li>
            <li>
              6. Uit de verwijzing blijkt dat er sprake is van een vermoeden van een DSM V stoornis
            </li>
          </ul>
        </div>
        <div className='pt-3'>
          <div className='font-semibold'>
            Zorgkosten 18 jaar en ouder: Vergoeding Zorgverzekeraar of Zelf betalen
          </div>
          In Nederland heeft iedereen over de totale ziektekosten per jaar een eigen bijdrage
          van 385,= . Als er sprake is van een diagnose, wordt daarna een deel van uw factuur
          vergoed. Wij rekenen het NZA-tarief. U krijgt van ons een rekening die u zelf aan ons
          betaalt en vervolgens kunt indienen bij uw zorgverzekeraar. De vergoeding van de
          verzekeraar aan u is afhankelijk van uw polis, globaal tussen 60-80%. Sinds 01-01-
          2022 liggen de tarieven vast per geplande sessie diagnostiek of behandeling, incl.
          administratietijd (het <a className='font-semibold cursor-pointer underline hover:text-main-blue' href='https://www.zorgprestatiemodel.nl/shared/content/uploads/2021/11/Patientfolder-zorgprestatiemodel-incl-illustraties-digitale-versie.pdf' target='_blank'>zorgprestatiemodel</a> ). Bij verzekerde zorg heeft u een
          verwijsbrief nodig die voldoet aan de eisen die de verzekeraars hieraan stellen. <br/>

          Wij hebben (nog) zeer beperkt contracten bij de zorgverzekeraars (<span className='font-semibold'>Uitsluitend DSW</span>)
          <div className='font-semibold pt-2'>
            Zelf betalen:
          </div>
          Indien er geen sprake is van een diagnose of wanneer u niet wilt dat
          er een diagnose wordt gesteld, betaalt u zelf de kosten. We hebben dan een
          aangepast tarief. Ook worden sommige problemen niet door de zorgverzekeraars
          vergoed. Bv: Zo valt rouw niet in vergoede zorg, maar complexe rouw wel. Soms is
          er vergoeding vanuit de aanvullende verzekering mogelijk.
          <div className='font-semibold pt-2'>
            Bij afspraken die minder dan 24 uur van te voren worden afgezegd of
            waarbij cliënten niet verschijnen, zijn wij, ongeacht de reden, genoodzaakt om
            in alle gevallen een tarief (90,= euro) bij u in rekening te brengen (ook bij
            verzekerde zorg). <span className='font-normal'>Dit staat vermeld in uw betalingsovereenkomst.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
// mock-data/content.ts
import { ContentData } from '@/types/cms';

const mockData: ContentData = {
  sections: [
    {
      id: 'main-page',
      name: 'Hoofd pagina',
      slug: 'main',
      blocks: [
        {
          id: 'main-1',
          key: 'mainTitle',
          value: 'Trauma, Verlies en Angst',
          type: 'text',
          description: 'Main page title',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'main-2',
          key: 'subTitle',
          value: 'Praktijk \'t Gooi',
          type: 'text',
          description: 'Main page subtitle',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'main-3',
          key: 'startDate',
          value: 'Per 1 januari 2025',
          type: 'text',
          description: 'Program start date',
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ]
    },
    {
      id: 'target-groups',
      name: 'Target Groups',
      slug: 'target-groups',
      blocks: [
        {
          id: 'target-1',
          key: 'targetGroups',
          value: 'Kinderen / Jeugd / (Jong) Volwassenen',
          type: 'text',
          description: 'Target groups',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'target-2',
          key: 'focus',
          value: 'Behandelen / Verwerken / Versterken',
          type: 'text',
          description: 'Focus areas',
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ]
    },
    {
      id: 'company-info',
      name: 'Bedrijfsinformatie',
      slug: 'company-info',
      blocks: [
        {
          id: 'comp-1',
          key: 'companyName',
          value: "Praktijk 't Gooi",
          type: 'text',
          description: 'Bedrijfsnaam',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'comp-2',
          key: 'tagline',
          value: 'Trauma, Verlies en Angst',
          type: 'text',
          description: 'Tagline',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'comp-3',
          key: 'email',
          value: 'info@traumaverliesangstpraktijkgooi.nl',
          type: 'email',
          description: 'Contact email',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'comp-4',
          key: 'phone',
          value: '06.12.34.45.67',
          type: 'phone',
          description: 'Contact telefoon',
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ]
    },
    {
      id: 'home-page',
      name: 'Homepage',
      slug: 'home',
      blocks: [
        {
          id: 'home-1',
          key: 'mainHeading',
          value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          type: 'text',
          description: 'Hoofdtekst op de homepage',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'home-2',
          key: 'introTitle',
          value: 'Korte introductie',
          type: 'text',
          description: 'Titel van de introductie sectie',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'home-3',
          key: 'introPrimary',
          value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tempor malesuada nulla sit amet consectetur. Quisque molestie nulla ultrices sapien gravida lacinia. Phasellus auctor vulputate dui eget dictum. Proin blandit risus eget lectus cursus, et molestie purus viverra. In eu odio justo. Mauris ut erat rutrum, feugiat leo sed.',
          type: 'text',
          description: 'Primaire introductie tekst',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'home-4',
          key: 'introSecondary',
          value: 'Aenean semper purus non velit vulputate, at laoreet sem dapibus. Donec luctus lectus nec ex tincidunt, ut finibus nisi porta. Donec nec consequat elit. Nulla et feugiat elit, vel tincidunt tortor. Pellentesque egestas mollis lorem, non pretium lorem ultricies eget.',
          type: 'text',
          description: 'Secundaire introductie tekst',
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ]
    },
    {
      id: 'contact',
      name: 'Contact',
      slug: 'contact',
      blocks: [
        {
          id: 'contact-1',
          key: 'pageTitle',
          value: 'Contact',
          type: 'text',
          description: 'Contact page title',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-2',
          key: 'pageDescription',
          value: 'Heeft u vragen of wilt u een afspraak maken? Neem gerust contact met ons op via onderstaand formulier of direct via telefoon of email.',
          type: 'text',
          description: 'Contact page description',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-3',
          key: 'formNameLabel',
          value: 'Naam',
          type: 'text',
          description: 'Form name field label',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-4',
          key: 'formEmailLabel',
          value: 'Email',
          type: 'text',
          description: 'Form email field label',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-5',
          key: 'formMessageLabel',
          value: 'Bericht',
          type: 'text',
          description: 'Form message field label',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-6',
          key: 'formSubmitButton',
          value: 'Verstuur',
          type: 'text',
          description: 'Form submit button text',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-7',
          key: 'directContactTitle',
          value: 'Direct Contact',
          type: 'text',
          description: 'Direct contact section title',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-8',
          key: 'availabilityTitle',
          value: 'Beschikbaarheid',
          type: 'text',
          description: 'Availability section title',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-9',
          key: 'waitingTime',
          value: 'Wachttijd tot intake is 4 weken. De praktijk heeft van september weer ruimte voor nieuwe aanmeldingen.',
          type: 'text',
          description: 'Waiting time information',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'contact-10',
          key: 'appointmentTitle',
          value: 'Afspraak maken',
          type: 'text',
          description: 'Appointment section title',
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ]
    },
    {
      id: 'team',
      name: 'Het Team',
      slug: 'team',
      blocks: [
        {
          id: 'team-1',
          key: 'pageTitle',
          value: 'Wie zijn wij',
          type: 'text',
          description: 'Titel van de teampagina',
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: 'team-2',
          key: 'pageDescription',
          value: `
            Wij zijn twee oud-collega’s die met veel enthousiasme 20 jaar geleden op de Kinder-en Jeugdpsychiatrie samenwerkten. De jaren erna hebben wij ons ieder op andere plekken doorontwikkeld in ons verder verdiept in de psychotherapie voor zowel jeugd en volwassenen. Daarbij zijn we altijd in contact gebleven, vooral met betrekking tot onze specialisatie op het gebied van diagnostiek behandeling van Trauma behandeling. Alexandra heeft ruim 20 jaar ervaring waarvan de laatste 14 jaar als zelfstandige gz- psycholoog/systeemtherapeut gewerkt met collega’s bij Het Lindehuis, Laren. Daarnaast is zij supervisor en docent bij de RINO (GZ-opleiding) en heeft zij lesgegeven aan de huisartsenopleiding. Reinilda heeft na een ruime carrière in de kinder- en jeugdpsychiatrie en als docent bij de RINO het roer omgegooid en de afgelopen 15 jaar gewerkt als zelfstandig gz- psycholoog/ cognitief gedragstherapeut in het Midden-Oosten (Qatar en Oman). Daarnaast heeft zij wetenschappelijke publicaties verzorgd en meegewerkt aan onderzoek- en de ontwikkeling van de gezondheidszorg voor het Ministerie van Gezondheidszorg en Welzijn in Qatar en Oman. Ook heeft zij verschillende schoolprojecten geleid. 
            <br/><br/> 
            Nu werken we weer samen in onderzoek – en behandeling van vragen over Trauma, Verlies en Angst- en Dwangklachten. Met name de keuze voor expertise en specialisme geeft enorm veel inspiratie, energie en plezier voor het vak waarbij de client en hulpvraag centraal staan.
          `,
          type: 'text',
          description: 'Beschrijving van het team',
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ],
      employees: [
        {
          id: '1',
          firstName: 'Drs. Alexandra ',
          lastName: 'Ridders',
          title: 'Gezondheidszorg -psycholoog',
          big: '39034812925',
          mainFocus: 'Systeemtherapeut',
          otherFocus: 'Supervisor NVRG en GZ',
          partOf: 'NIP, VEN, NVRG',
          languagues: 'Nederlands, Engels',
          email: 'sarah@traumaverliesangstpraktijkgooi.nl',
          phone: '06.12.34.56.78',
          imageUrl: 'https://cbx-prod.b-cdn.net/COLOURBOX61506722.jpg?width=800&height=800&quality=70',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tempor malesuada nulla sit amet consectetur. Quisque molestie nulla ultrices sapien gravida lacinia.',
          active: true,
          lastUpdated: '2024-03-15T12:00:00Z'
        },
        {
          id: '2',
          firstName: 'Drs. Reinilda',
          lastName: 'Dernison',
          title: 'Kinder Psycholoog',
          big: '12341234',
          mainFocus: 'Cognitief Gedragstherapeut',
          otherFocus: '',
          partOf: 'NIP, VEN, NVRG',
          languagues: 'Nederlands, Engels',
          email: 'michael@traumaverliesangstpraktijkgooi.nl',
          phone: '06.98.76.54.32',
          imageUrl: 'https://media.istockphoto.com/id/1471478144/photo/smiling-young-black-female-doctor-psychologist-consulting-european-woman-in-modern-clinic.jpg?s=612x612&w=0&k=20&c=0mS_NIXbZFg2OAy9c5boOUjjexomsNQqhykcY7wIeQU=',
          description: 'Aenean semper purus non velit vulputate, at laoreet sem dapibus. Donec luctus lectus nec ex tincidunt, ut finibus nisi porta.',
          active: true,
          lastUpdated: '2024-03-15T12:00:00Z'
        }
      ]
    },
  ]
};

export default mockData;
// pages/WhoWeAre.tsx
import React from 'react';
import Header from '@/components/ui/header';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import mockData from '@/mock-data/content';
import { Employee } from '@/types/cms';

import AlexandraImg from '@/images/alexandra.jpg';

const EmployeeCard: React.FC<{ employee: Employee }> = ({ employee }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <img 
      // src={employee.imageUrl} 
      src={employee.lastName.includes('Ridders') ? AlexandraImg : employee.imageUrl}
      alt={`${employee.firstName} ${employee.lastName}`} 
      className="w-full h-64 object-cover object-center"
    />
    <div className="p-6">
      <h3 className="text-2xl font-semibold text-blue-900">
        {`${employee.firstName} ${employee.lastName}`}
      </h3>
      <p className="text-lg text-blue-800 mb-4">{employee.title}</p>
      <div className="space-y-2 mb-6">
        <div className="flex items-center">
          <EnvelopeIcon className="h-5 w-5 text-blue-800 mr-2" />
          <a href={`mailto:${employee.email}`} className="text-blue-600 hover:text-blue-800">
            {employee.email}
          </a>
        </div>
        <div className="flex items-center">
          <PhoneIcon className="h-5 w-5 text-blue-800 mr-2" />
          <p className="text-gray-700">{employee.phone}</p>
        </div>
        <div className='text-sm'>
          <div>
            {employee.mainFocus}
          </div>
          <div className=''>
            {employee.otherFocus}
          </div>
          <div className='text-sm'>
            European EMDR-practitioner
          </div>
          <div className=''>
            Lid van: {employee.partOf}
          </div>
          <div className=''>
            Talen: {employee.languagues}
          </div>
        </div>
      </div>
      {/* <p className="text-gray-700">{employee.description}</p> */}
    </div>
  </div>
);

const WhoWeAre = () => {
  const teamSection = mockData.sections.find(s => s.id === 'team');
  const activeEmployees = teamSection?.employees?.filter(e => e.active) || [];

  const getContent = (key: string) => {
    return teamSection?.blocks.find(b => b.key === key)?.value || '';
  };

  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <div className="text-justify mb-12">
          <h1 className="text-4xl font-bold text-blue-900 mb-6">
            {getContent('pageTitle')}
          </h1>
          <div className='flex flex-col md:flex-row gap-x-10'>
            <div className="text-lg text-gray-700 mx-auto md:w-2/3" >
              {/* {getContent('pageDescription')} dangerouslySetInnerHTML={{__html: getContent('pageDescription')}} */}
              Wij zijn twee oud-collega’s die met veel enthousiasme 20 jaar geleden op de Kinder-en Jeugdpsychiatrie samenwerkten. De jaren erna hebben wij ons ieder op andere plekken doorontwikkeld in ons verder verdiept in de psychotherapie voor zowel jeugd en volwassenen. Daarbij zijn we altijd in contact gebleven, vooral met betrekking tot onze specialisatie op het gebied van diagnostiek behandeling van Trauma behandeling. Alexandra heeft ruim 20 jaar ervaring waarvan de laatste 14 jaar als zelfstandige gz- psycholoog/systeemtherapeut gewerkt met collega’s bij Het Lindehuis, Laren. 
              <br/><br/>
              Daarnaast is zij supervisor en docent bij de RINO (GZ-opleiding) en heeft zij lesgegeven aan de huisartsenopleiding. Reinilda heeft na een ruime carrière in de kinder- en jeugdpsychiatrie en als docent bij de RINO het roer omgegooid en de afgelopen 15 jaar gewerkt als zelfstandig gz- psycholoog/ cognitief gedragstherapeut in het Midden-Oosten (Qatar en Oman). Daarnaast heeft zij wetenschappelijke publicaties verzorgd en meegewerkt aan onderzoek- en de ontwikkeling van de gezondheidszorg voor het Ministerie van Gezondheidszorg en Welzijn in Qatar en Oman. Ook heeft zij verschillende schoolprojecten geleid. 
              <br/><br/> 
              Nu werken we weer samen in onderzoek – en behandeling van vragen over Trauma, Verlies en Angst- en Dwangklachten. Met name de keuze voor expertise en specialisme geeft enorm veel inspiratie, energie en plezier voor het vak waarbij de client en hulpvraag centraal staan.
            </div>
            <div className='md:w-1/3'>
              <img src={"https://www.fontys.nl/upload/82d36414-93cb-4641-b7b0-90e4f48851b4_Communicatieve%20taaltherapie%20_%20Fontys%20_%20Cursus-min.png"} alt='team' className='rounded-lg h-full object-cover object-right' />
            </div>
          </div>
        </div>
        <div className='text-center mb-4'>
          Wij zijn aangesloten bij de klachten – en geschillencommissie van NIP
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          {activeEmployees.map(employee => (
            <EmployeeCard key={employee.id} employee={employee} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
// Dashboard.tsx
import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import mockData from '@/mock-data/content';
import { ContentBlock } from '@/types/cms';
import EmployeeManagement from './EmployeeManagement';
import { useAuth } from '@/contexts/AuthContext';

const CMSDashboard = () => {
  const { logout } = useAuth();
  const [sections, setSections] = React.useState(mockData.sections);
  const [isDirty, setIsDirty] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(sections[0].id);

  const handleContentChange = (sectionId: string, blockId: string, newValue: string) => {
    setSections(prevSections =>
      prevSections.map(section => {
        if (section.id === sectionId) {
          return {
            ...section,
            blocks: section.blocks.map(block =>
              block.id === blockId
                ? { ...block, value: newValue, lastUpdated: new Date().toISOString() }
                : block
            )
          };
        }
        return section;
      })
    );
    setIsDirty(true);
  };

  const handleSave = async () => {
    try {
      // TODO: Implement API call
      console.log('Saving changes:', sections);
      setIsDirty(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const renderContentBlock = (block: ContentBlock) => {
    if (block.type === 'section-title') {
      return (
        <div key={block.id} className="mt-8 mb-4 first:mt-0">
          <h3 className="text-lg font-semibold text-blue-900 border-b border-gray-200 pb-2">
            {block.value}
          </h3>
        </div>
      );
    }

    return (
      <div key={block.id} className="space-y-2">
        <div className="flex justify-between items-center">
          <label className="text-sm font-medium text-gray-700">
            {block.description}
          </label>
          <span className="text-xs text-gray-500">
            {new Date(block.lastUpdated).toLocaleString('nl-NL')}
          </span>
        </div>
        <input
          type={block.type === 'email' ? 'email' : block.type === 'phone' ? 'tel' : 'text'}
          value={block.value}
          onChange={(e) => handleContentChange(sections.find(s => 
            s.blocks.some(b => b.id === block.id)
          )?.id || '', block.id, e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                   focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2
                   bg-white"
        />
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-main-black">Dashboard</h1>
            <div className="flex items-center gap-4">
              {isDirty && (
                <span className="text-sm text-amber-600">
                  Er zijn niet-opgeslagen wijzigingen
                </span>
              )}
              <button
                onClick={handleSave}
                disabled={!isDirty}
                className={`px-4 py-2 rounded-md text-white ${
                  isDirty 
                    ? 'bg-blue-600 hover:bg-blue-700' 
                    : 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                Wijzigingen opslaan
              </button>
              <button
                onClick={logout}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Uitloggen
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Tabs defaultValue={sections[0].id} value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="w-full justify-start mb-8 bg-white p-1 border rounded-lg">
            {sections.map((section) => (
              <TabsTrigger
                key={section.id}
                value={section.id}
                className="px-4 py-2"
              >
                {section.name}
              </TabsTrigger>
            ))}
          </TabsList>
          
          {sections.map((section) => (
            <TabsContent key={section.id} value={section.id}>
              <Card>
                <CardContent className="p-6">
                  {section.id === 'employees' ? (
                    <EmployeeManagement tabValue={activeTab} />
                  ) : (
                    <div className="space-y-6">
                      {section.blocks.map(renderContentBlock)}
                    </div>
                  )}
                </CardContent>
              </Card>
            </TabsContent>
          ))}
        </Tabs>
      </main>
    </div>
  );
};

export default CMSDashboard;

const Footer = () => {
  
  return (
    <div className="fixed bottom-0 w-screen bg-white/90 hidden md:block items-center">
      <div className="text-center py-5">
        <span className="font-bold">Let op:</span> kijk onder ‘wachttijden’ voor de mogelijkheden om je op dit moment bij ons aan te melden
      </div>
    </div>
  );
};

export default Footer;
// components/dashboard/EmployeeManagement.tsx
import React from 'react';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Employee } from '@/types/cms';
import mockData from '@/mock-data/content';

interface EmployeeFormProps {
  employee: Employee;
  onChange: (updatedEmployee: Employee) => void;
  onDelete: () => void;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ employee, onChange, onDelete }) => {
  const handleChange = (field: keyof Employee, value: string | boolean) => {
    onChange({
      ...employee,
      [field]: value,
      lastUpdated: new Date().toISOString()
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex justify-between items-start mb-6">
        <h4 className="text-lg font-medium text-main-black">
          {employee.firstName} {employee.lastName}
        </h4>
        <button
          onClick={onDelete}
          className="text-red-500 hover:text-red-700 p-2"
          title="Verwijder medewerker"
        >
          <TrashIcon className="w-5 h-5" />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Voornaam</label>
          <input
            type="text"
            value={employee.firstName}
            onChange={(e) => handleChange('firstName', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Achternaam</label>
          <input
            type="text"
            value={employee.lastName}
            onChange={(e) => handleChange('lastName', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Functie</label>
          <input
            type="text"
            value={employee.title}
            onChange={(e) => handleChange('title', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Afbeelding URL</label>
          <input
            type="text"
            value={employee.imageUrl}
            onChange={(e) => handleChange('imageUrl', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={employee.email}
            onChange={(e) => handleChange('email', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Telefoon</label>
          <input
            type="tel"
            value={employee.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div className="md:col-span-2">
          <label className="block text-sm font-medium text-gray-700">Beschrijving</label>
          <textarea
            value={employee.description}
            onChange={(e) => handleChange('description', e.target.value)}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm p-2"
          />
        </div>

        <div className="md:col-span-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={employee.active}
              onChange={(e) => handleChange('active', e.target.checked)}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <span className="text-sm font-medium text-gray-700">Actief</span>
          </label>
        </div>
      </div>
    </div>
  );
};

const EmployeeManagement: React.FC<{ tabValue: string }> = ({ tabValue }) => {
  const [employees, setEmployees] = React.useState<Employee[]>(
    mockData.sections.find(s => s.id === 'employees')?.employees || []
  );
  const handleAddEmployee = () => {
    const newEmployee: Employee = {
      id: `emp-${Date.now()}`,
      firstName: 'Nieuwe',
      lastName: 'Medewerker',
      title: '',
      big: '',
      mainFocus: '',
      otherFocus: '',
      partOf: '',
      languagues: '',
      imageUrl: '/images/placeholder.jpg',
      email: '',
      phone: '',
      description: '',
      active: true,
      lastUpdated: new Date().toISOString()
    };
    setEmployees([...employees, newEmployee]);
  };

  const handleUpdateEmployee = (updatedEmployee: Employee) => {
    setEmployees(employees.map(emp => 
      emp.id === updatedEmployee.id ? updatedEmployee : emp
    ));
  };

  const handleDeleteEmployee = (employeeId: string) => {
    if (window.confirm('Weet je zeker dat je deze medewerker wilt verwijderen?')) {
      setEmployees(employees.filter(emp => emp.id !== employeeId));
    }
  };

  if (tabValue !== 'employees') return null;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-main-black">Medewerkers Beheer</h3>
        <button
          onClick={handleAddEmployee}
          className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          <PlusCircleIcon className="w-5 h-5" />
          <span>Nieuwe Medewerker</span>
        </button>
      </div>

      <div className="space-y-6">
        {employees.length === 0 ? (
          <p className="text-gray-500 text-center py-8">
            Nog geen medewerkers toegevoegd.
          </p>
        ) : (
          employees.map(employee => (
            <EmployeeForm
              key={employee.id}
              employee={employee}
              onChange={handleUpdateEmployee}
              onDelete={() => handleDeleteEmployee(employee.id)}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default EmployeeManagement;
// pages/about/Vision.tsx
import React from 'react';
import Header from '@/components/ui/header';

import image from '@/images/supervisie.jpeg';

const Coaching = () => {
  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-6">Supervisie, coaching en begeleide intervisie</h1>
        
        <div className="text-lg text-gray-700 mb-12"> 
          <div>
            Bij onze praktijk is er de mogelijkheid om supervisie of coaching voor
            zorgprofessionals te volgen. Je kunt kiezen tussen individuele- en groepssupervisie.
            Dit kan zowel in het kader van jouw BIG opleiding of een opleidingstraject bij de
            NVRG of de VGCT. <br/>
            Uiteraard kun je ook supervisie- of coaching vragen als je een ander opleidingstraject
            doet als zorgprofessional of wanneer je meer verdieping wil in je werk. <br/><br/>
          </div>
          <div className='flex flex-col md:flex-row md:gap-x-4 items-center'>
            <div className='flex-1'>
              Er zijn verschillende opties voor psychologen die een BIG opleiding volgen;
              <li> Individuele supervisie reeks 45 uur (1 supervisor)</li>
              <li> Individuele supervisiereeks 46 uur waarvan 13 accent systeemtherapie en 13
              uur accent cgt uur (2 supervisoren)</li>
              <li> Groepssupervisie GZ -opleiding ; bij 2 supervisoren met accenten zowel
              systeemtherapie als cgt</li>
              <li> NVRG -supervisie reeks, Alexandra Ridders supervisor-opleider NVRG</li>
              <li> VGCT-supervisiereeks, Reinilda Dernison supervisor VGCT (vanaf April
              2025)</li> 
            </div>
            <div className='flex-1'>
              <img src={image} alt='team' className='rounded-lg h-full object-cover object-right' />
            </div>
          </div>
            <br/>
            Er zijn diverse opties voor coaching of supervisie voor zorgprofessionals (o.m.
            huisartsen-en specialisten en verpleegkundigen in opleiding). We bespreken met jou
            een traject op maat, passend bij jouw situatie.
            <br/><br/>
            Supervisie en coaching zijn zowel gericht op jouw persoonlijke- en professionele
            ontwikkeling. Hierbij sluiten we aan op jouw vragen en de eventuele plek binnen je
            opleidingstraject of werk.
        </div>
      </div>
    </div>
  );
};

export default Coaching;
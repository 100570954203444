// pages/Contact.tsx
import React, { useState } from 'react';
import Header from '@/components/ui/header';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import mockData from '@/mock-data/content';

interface FormData {
  name: string;
  email: string;
  message: string;
}

const Contact = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });

  const isValidEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isFormValid = (): boolean => {
    return (
      formData.name.trim() !== '' &&
      formData.message.trim() !== '' &&
      isValidEmail(formData.email)
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid()) {
      // TODO: Implement form submission
      console.log('Form submitted:', formData);
    }
  };

  const getContent = (sectionId: string, key: string) => {
    const section = mockData.sections.find(s => s.id === sectionId);
    return section?.blocks.find(b => b.key === key)?.value || '';
  };

  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-blue-900 mb-4">
            {getContent('contact', 'pageTitle')}
          </h1>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            {getContent('contact', 'pageDescription')}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  {getContent('contact', 'formNameLabel')}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {getContent('contact', 'formEmailLabel')}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className={`p-2 mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500
                    ${!formData.email || isValidEmail(formData.email) 
                      ? 'border-gray-300 focus:border-blue-500' 
                      : 'border-red-300 focus:border-red-500'}`}
                />
                {formData.email && !isValidEmail(formData.email) && (
                  <p className="mt-1 text-sm text-main-black">
                    Voer een geldig emailadres in
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  {getContent('contact', 'formMessageLabel')}
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows={6}
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <button
                type="submit"
                disabled={!isFormValid()}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                  ${isFormValid()
                    ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer'
                    : 'bg-gray-400 cursor-not-allowed'}`}
              >
                {getContent('contact', 'formSubmitButton')}
              </button>
            </form>
          </div>

          {/* Contact Information */}
          <div className="space-y-8">
            <div className="bg-blue-50 rounded-lg p-6">
              <h3 className="text-xl font-semibold text-blue-900 mb-4">
                {getContent('contact', 'directContactTitle')}
              </h3>
              <div className="space-y-4">
                <div className="flex items-center">
                  <PhoneIcon className="h-5 w-5 text-blue-800 mr-2" />
                  <div>
                    <p className="font-medium text-blue-800">Telefoon</p>
                    <p className="text-blue-900">{getContent('company-info', 'phone')}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <EnvelopeIcon className="h-5 w-5 text-blue-800 mr-2" />
                  <div>
                    <p className="font-medium text-blue-800">Email</p>
                    <a 
                      href={`mailto:${getContent('company-info', 'email')}`}
                      className="text-blue-600 hover:text-blue-800 break-all"
                    >
                      {getContent('company-info', 'email')}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h3 className="text-xl font-semibold text-blue-900 mb-4">
                {getContent('contact', 'availabilityTitle')}
              </h3>
              <p className="text-gray-700">
                {getContent('contact', 'waitingTime')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
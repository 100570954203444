// pages/about/Vision.tsx
import React from 'react';
import Header from '@/components/ui/header';

const Quality = () => {
  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-6">Kwaliteit, klachten en privacy</h1>
        
        <p className="text-lg text-gray-700 mb-12">
          <p className='text-gray-700 font-bold'> 
            Kwaliteit
          </p>
          Sinds 2017 zijn wij verplicht om een kwaliteitsstatuut te hebben. Hierin staat
          beschreven hoe de zorg is geregeld. Onze kwaliteitsstatuten kunt hier inzien.
          <p className='text-gray-700 font-bold pt-2'>
            Klachten
          </p>
          Als je niet tevreden bent over de behandeling of de manier waarop je werd benaderd,
          hopen wij uiteraard dat je dit met ons bespreekt.
          Mochten wij er niet uitkomen dan heb je de mogelijkheid om dit voor te leggen aan
          de klachten- en geschillencommissie van het NIP.
          <p className='text-gray-700 font-bold pt-2'>
            Privacy
          </p>
          In de zorg is privacy een belangrijk onderwerp. Als cliënt moet je ervan uit kunnen
          gaan dat gegevens vertrouwelijk worden behandeld. Wij hebben daarom een privacy
          statement (Link naar tekst zie nu onderaan) opgesteld.
          Daarbij maken wij altijd afspraken met je, over hoe wij met jouw informatie
          omgaan. Wij mogen bijvoorbeeld geen contact met je huisarts opnemen zonder jouw
          toestemming.  Voor het eerste gesprek sturen we je een toestemmingsverklaring.
          Daarop kun je aangeven welke informatie met wie gedeeld mag worden
          <p className='text-gray-700 font-bold pt-4'>
          Meldcode
          </p>
          Vanaf 1 juli 2013 is de wet meldcode huiselijk geweld en kindermishandeling van
          kracht. De wet stelt het gebruik van de meldcode verplicht voor professionals bij
          (mogelijke) signalen van huiselijk geweld en kindermishandeling. De meldcode
          bestaat uit een stappenplan, waarin staat wat je als professional moet doen bij
          vermoedens van huiselijk geweld of kindermishandeling. Doel van de wet is niet het
          melden maar hulp op gang krijgen. <a href="https://www.rijksoverheid.nl/onderwerpen/huiselijk-geweld/meldcode" target="_blank" className=" underline">Meldcode Huiselijk geweld en kindermishandeling
          | Huiselijk geweld | Rijksoverheid.nl</a>
          <p className='text-gray-700 font-bold pt-2'>
          Klachten
          </p>
          Uw behandelaar bij Trauma, Verlies en Angst Praktijk ‘t Gooi werkt volgens de
          beroepscodes <a href="https://wetten.overheid.nl/BWBR0005290/2015-11-26/1" target="_blank" className=" underline"> Wet op de Geneeskundige Behandel Overeenkomst</a> (WPGO) en de
          richtlijnen van het Nederlands Instituut voor Psychologen (NIP). Bij een eventuele
          klacht stellen we het op prijs als u ons daarvan in kennis stelt. Wij zullen u dan een
          gesprek aanbieden dat specifiek op de klacht en de eventuele oplossing ervan
          gericht is. Dat kan (eerst) bij uw eigen behandelaar, in overleg met een aanwezige
          collega. Komen we er samen niet uit of wilt u liever een neutrale beoordeling, dan
          kunt u ook een klacht indienen bij de beroepsorganisatie. Wij zijn beide aangesloten
          bij het NIP:
          <a className=' underline' href="https://www.psynip.nl/uw-beroep/tuchtrecht-en-klachten/" target="_blank">https://www.psynip.nl/uw-beroep/tuchtrecht-en-klachten/</a>
          
          <p className='text-gray-700 font-bold pt-4'>
          Dossierbeheer
          </p>
          Dossiers worden zoals de wet voorschrijft gedurende x jaar bewaard en worden
          daarna vernietigd. Zie ook onze privacy policy, conform de wet <a className=' underline' target="_blank" href="https://praktijk.co/praktijkinfo/algemene-verordening-gegevensbescherming-avg/">Algemene
          verordening gegevensbescherming (AVG).</a>
          <p className='text-gray-700 font-bold pt-2'>
          Toestemmingsverklaring gescheiden ouders
          </p>
          Indien je gescheiden bent en je beiden gezag hebt over een kind dat jonger is dan 16
          jaar, dan kan de hulpverlening pas starten wanneer beide ouders daar toestemming
          voor geven. Dit is wettelijk vastgelegd. <a className=' underline' target='_blank' href="https://storage.googleapis.com/pro_web/sites/22/2015/11/gezag-omgang-en-informatie-2015-juli.pdf">Hier vind je meer informatie over ouderlijk
          gezag.</a>

          <p className='text-gray-700 font-bold pt-4'>
          Privacy Policy Trauma, Verlies en Angst Praktijk ‘t Gooi
          Het Lindehuis hecht veel waarde aan de bescherming van uw
          persoonsgegevens. In dit privacy beleid willen we heldere en transparante
          informatie geven over hoe wij omgaan met persoonsgegevens.
          </p>
          Wij doen er alles aan om uw privacy te waarborgen en gaan daarom zorgvuldig om
          met persoonsgegevens. Trauma, Verlies en Angst Praktijk ‘t Gooi houdt zich in alle
          gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene
          Verordening Gegevensbescherming.  Dit brengt met zich mee dat wij in ieder geval:
          <ul>
            <li>
              - uw persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze
              zijn verstrekt, deze doelen en type persoonsgegevens zijn beschreven in dit privacy
              beleid.
            </li>
            <li>
            - verwerking van uw persoonsgegevens beperkt blijft tot enkel die gegevens welke
            minimaal nodig zijn voor de doeleinden waarvoor ze worden verwerkt.</li>
            <li>- vragen om uw uitdrukkelijke toestemming als wij deze nodig hebben voor de
            verwerking van uw persoonsgegevens.</li>
            <li>- passende technische en/of organisatorische maatregelen hebben genomen zodat
            de beveiliging van uw persoonsgegevens gewaarborgd is.</li>
            <li>- geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor
            uitvoering van de doeleinden waarvoor ze zijn verstrekt.</li>
            <li>- op de hoogte zijn van uw rechten omtrent uw persoonsgegevens, u hier op willen
            wijzen en deze respecteren.</li>
            <li>- geen cookies gebruiken op onze website.</li>
          </ul>
          <p className='pt-2'>
          Trauma, Verlies en Angst Praktijk ‘t Gooi zijn wij verantwoordelijk voor de verwerking
          van uw persoonsgegevens. Indien u na het doornemen van ons privacy beleid, of in
          algemenere zin, vragen hebt hierover of contact met ons wenst op te nemen kan dit
          via onderstaande contactgegevens:
          <br/><br/>Trauma, Verlies en Angst Praktijk ’t Gooi
          <br/>Laapersveld 75
          <br/>1213 VB Hilversum
          <br/>info@traumaverliesangstpraktijkgooi.nl
          </p>
        </p>
      </div>
    </div>
  );
};

export default Quality;
// pages/about/Vision.tsx
import React from 'react';
import Header from '@/components/ui/header';

import image from '@/images/samen.jpeg';

const Method = () => {
  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-6">Onze werkwijze</h1>
        <div className='flex flex-col md:gap-x-4 md:flex-row items-center'>
          <div className='flex-1 md:w-1/4 '>
            <img src={image} alt='team' className='rounded-lg object-cover object-right' />
          </div>
          <div className='md:w-3/4 mt-4 md:mt-0'>
            <p className='text-gray-700 font-bold'>
              Wij bieden specialistische hulp voor Trauma, Verlies en Angst- en dwangklachten door individueel én systemisch te werken.
            </p>
            <br/>
            <p className="text-lg text-gray-700 mb-12">
              We brengen daarbij goed in kaart wat er speelt bij jou en/of jouw naasten. Hierbij doen we wat nodig is om tot een goed behandeladvies te komen (geen onnodige diagnostiek). We delen onze visie met jou en komen met jou samen tot een behandelplan dat bij je past. In overleg starten we daarna direct de behandeling. Tussentijds zijn er evaluaties, stellen we de behandeling waar nodig soms bij en we ronden in overleg de behandeling (weer) af.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Method;
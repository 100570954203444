// App.tsx
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CMSDashboard from './CMS/Dashboard';
import { AuthProvider } from './contexts/AuthContext';
import Login from './pages/Login';
import OnePager from './pages/OnePager';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Home from './pages/Home';
import { Swirl } from './components/ui/swirl';
import WhoWeAre from './pages/WhoWeAre';
import Vision from './pages/about/Vision';
import Method from './pages/about/Method';
import Contact from './pages/Contact';
import Expertise from './pages/about/Expertise';
import Pricing from './pages/about/Pricing';
import Quality from './pages/about/Quality';
import Coaching from './pages/about/Coaching';
import Footer from './components/ui/footer';

const App = () => {
  return (
    <AuthProvider>
      <div className="fixed inset-0 -z-10 text-main-black">
        <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-blue-100/50 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
        <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-blue-200/50 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
        {/* Swirls */}
        <div className="absolute -top-20 right-0 text-main-blue/20 w-96 h-[500px] transform rotate-[100deg]">
          <Swirl />
        </div>
        <div className="absolute -top-40 right-20 text-main-blue w-96 h-[500px] transform rotate-[100deg]">
          <Swirl />
        </div>
        <div className="absolute -bottom-20 left-0 text-main-blue/20 w-96 h-96 transform -rotate-[20deg]">
          <Swirl />
        </div>
        <div className="absolute -bottom-32 left-20 text-main-blue w-96 h-96 transform -rotate-[60deg]">
          <Swirl />
        </div>
      </div>
      <div className='pb-20'> 
        <Router>
          <Routes> 
            {/* Public routes */}
            <Route path="/" element={<OnePager />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/team" element={<WhoWeAre />} />
            <Route path="/contact" element={<Contact />} />
            
            {/* About routes */}
            <Route path="/about/vision" element={<Vision />} />
            <Route path="/about/method" element={<Method />} />
            <Route path="/about/expertise" element={<Expertise />} />
            <Route path="/about/kosten" element={<Pricing />} />
            <Route path="/about/kwaliteit" element={<Quality />} />
            <Route path="/about/coaching" element={<Coaching />} />

            {/* Protected admin routes */}
            <Route 
              path="/admin/*" 
              element={
                <ProtectedRoute>
                  <Routes>
                    <Route path="/" element={<CMSDashboard />} />
                    {/* Add more admin routes here */}
                    <Route path="*" element={<Navigate to="/admin" replace />} />
                  </Routes>
                </ProtectedRoute>
              } 
            />

            {/* Catch all - redirect to home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>

      <div>
        <Footer />
      </div>
    </AuthProvider>
  );
};

export default App;
// pages/Home.tsx
import React from 'react';
import logo from '@/images/logo.svg';
import Header from '@/components/ui/header';
import { CalendarIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import mockData from '@/mock-data/content';

const Home = () => {
  const companyInfo = mockData.sections.find(s => s.id === 'company-info');
  const homePage = mockData.sections.find(s => s.id === 'home-page');

  const getContent = (sectionId: string, key: string) => {
    const section = mockData.sections.find(s => s.id === sectionId);
    return section?.blocks.find(b => b.key === key)?.value || '';
  };

  return (
    <div className="min-h-screen">
      <Header />
      
      {/* Hero Section with Logo and Headings */}
      <header className="relative">
        {/* <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:flex flex-row items-center justify-between">
            <img 
              src={logo} 
              alt={`${getContent('company-info', 'companyName')} Logo`}
              className="w-auto max-h-[250px]"
            />
            <div className="text-left ml-8 flex-1 text-center">
              <h2 className="text-5xl text-blue-800 font-bold font-serif">
                {getContent('company-info', 'tagline')}
              </h2>
              <h1 className="text-3xl text-blue-900 mb-4">
                {getContent('company-info', 'companyName')}
              </h1>
            </div>
          </div>
        </div> */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-12">
          <div className="w-fit m-auto">
            <img 
              src={logo} 
              alt={`${getContent('company-info', 'companyName')} Logo`}
              className="w-auto max-h-[250px]"
            />
          </div>
        </div>
        <div className='max-w-6xl text-center w-fit m-auto pt-12'>
          <h1 className='font-bold text-3xl '>
            {/* {getContent('home-page', 'mainHeading')} */}
            Heb je één of meerdere nare gebeurtenissen meegemaakt, recent of in je jeugd of heb je last van angst waar je geen grip op krijgt? Dan kun jij bij ons terecht.
          </h1>
          <h3 className='font-bold text-2xl pt-2'>
            Behandeling kan zijn voor jou individueel, maar ook voor je partner of gezin. Jeugd, (jong-)Volwassene, Gezin
          </h3>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Introduction Section */}
          <div className="md:col-span-2 gap-y-2">
          <p className='font-bold'>Trauma</p> 
          Wij zijn gespecialiseerd in de behandeling van psychische klachten die samenhangen met heftige ingrijpende ervaringen, zoals een (verkeers-)ongeval, seksueel misbruik, ziekte, zwangerschap of een nare gebeurtenis die je hebt meegemaakt bij de uitoefening van je werk. De meeste psychotrauma-klachten zijn goed te behandelen.
          
          <p className='font-bold pt-2'>Verlies </p>
          Ook kun je bij ons terecht als je een verlies hebt meegemaakt door bv overlijden of ziekte, een lichaam dat niet meer functioneert als voorheen of op een andere manier.
          
          <p className='font-bold pt-2'>Angst- en dwangklachten</p>
          Heb je angst- of dwangklachten die jou belemmeren in je dagelijks functioneren dan kun je hiervoor ook aanmelden bij Trauma, verlies en Angst Praktijk 't Gooi. 
          <br/><br/>
          Daarnaast geven we supervisie aan gz-psychologen en systeemtherapeuten en cognitief gedragstherapeuten in opleiding (combinatie-trajecten). Ook kunnen we op maat voorlichting en trainingen en supervisie geven aan artsen(i.o.) en scholen en ondersteunen we individueel personeel en teams van organisaties die werken in risicovolle beroepen (denk aan; ziekenhuizen, politie, brandweer)
          <br/><br/>
          {/* Tenslotte bieden geven we supervisie aan gz-psychologen en systeemtherapeuten. */}

            {/* <h3 className="text-2xl font-semibold text-blue-900 mb-6">
              {getContent('home-page', 'introTitle')}
            </h3>
            <div className="prose prose-blue max-w-none">
              <p className="text-gray-700 font-bold">
                {getContent('home-page', 'introPrimary')}
              </p>
              <p className="text-gray-700 pt-2">
                {getContent('home-page', 'introSecondary')}
              </p>
            </div> */}
          </div>

          {/* Contact Card */}
          <div className="space-y-4">
            <div className="bg-blue-50 rounded-lg p-6">
              <h3 className="text-xl font-semibold text-blue-900 mb-4 flex items-center">
                <CalendarIcon className="h-5 w-5 mr-2" />
                {getContent('contact', 'appointmentTitle')}
              </h3>
              <div className="space-y-4">
                <div className="flex items-center">
                  <PhoneIcon className="h-5 w-5 text-blue-800 mr-2" />
                  <div>
                    <p className="font-medium text-blue-800">Telefoon</p>
                    <p className="text-blue-900">{getContent('company-info', 'phone')}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <EnvelopeIcon className="h-5 w-5 text-blue-800 mr-2" />
                  <div>
                    <p className="font-medium text-blue-800">Email</p>
                    <a 
                      href={`mailto:${getContent('company-info', 'email')}`}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      {getContent('company-info', 'email')}
                    </a>
                  </div>
                </div>
                <div>
                  <p className="font-medium text-blue-800">Wachttijd</p>
                  <p className="text-gray-700">
                    {getContent('contact', 'waitingTime')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
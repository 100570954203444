// components/ui/Header.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDownIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import headerLogo from '@/images/header-logo.svg';
import logo from '@/images/logo.svg';
import { Swirl } from './swirl';

const aboutItems = [
  { name: 'Visie', path: '/about/vision' },
  { name: 'Werkwijze', path: '/about/method' },
  { name: 'Expertise', path: '/about/expertise' },
  { name: 'Supervisie, coaching en begeleide intervisie', path: '/about/coaching' },
  { name: 'Kwaliteit, klachten & privacy', path: '/about/kwaliteit' },
  { name: 'Kosten', path: '/about/kosten' },
];

const MobileMenuItem = ({ to, onClick, children }: { to: string; onClick: () => void; children: React.ReactNode }) => (
  <Link 
    to={to} 
    onClick={onClick}
    className="block text-2xl font-medium text-main-black py-4 text-center hover:text-blue-600 transition-colors"
  >
    {children}
  </Link>
);

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileAboutOpen, setIsMobileAboutOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isAboutActive = currentPath.startsWith('/about/');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsAboutOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const mobileMenuVariants = {
    closed: { x: "100%", transition: { type: "spring", stiffness: 300, damping: 30 } },
    open: { x: 0, transition: { type: "spring", stiffness: 300, damping: 30 } }
  };

  const mobileSubMenuVariants = {
    closed: { height: 0, opacity: 0 },
    open: { height: "auto", opacity: 1, transition: { duration: 0.3 } }
  };

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <img className="h-16 w-auto" src={headerLogo} alt="Logo" />
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex md:space-x-8 w-fit m-auto">
            <Link 
              to="/home" 
              className={`${currentPath === '/home' ? 'border-blue-500 text-main-black' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium h-16`}
            >
              Home
            </Link>
            
            {/* About Dropdown */}
            <div ref={dropdownRef} className="relative h-16 flex items-center">
              <button
                onClick={() => setIsAboutOpen(!isAboutOpen)}
                className={`${isAboutActive ? 'border-blue-500 text-main-black' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 border-b-2 text-sm font-medium h-16`}
              >
                Praktijkinfo
                <ChevronDownIcon className={`ml-1 h-4 w-4 transform transition-transform ${isAboutOpen ? 'rotate-180' : ''}`} />
              </button>

              {isAboutOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 top-full">
                  <div className="py-1" role="menu">
                    {aboutItems.map((item) => (
                      <Link
                        key={item.path}
                        to={item.path}
                        onClick={() => setIsAboutOpen(false)}
                        className={`${currentPath === item.path ? 'bg-gray-100 text-main-black' : 'text-gray-700'} block px-4 py-2 text-sm hover:bg-gray-50`}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <Link 
              to="/team" 
              className={`${currentPath === '/team' ? 'border-blue-500 text-main-black' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium h-16`}
            >
              Het team
            </Link>
            <Link 
              to="/contact" 
              className={`${currentPath === '/contact' ? 'border-blue-500 text-main-black' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium h-16`}
            >
              Contact
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-main-black hover:bg-gray-100 focus:outline-none"
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div 
            className="fixed inset-0 bg-white z-50"
            initial="closed"
            animate="open"
            exit="closed"
            variants={mobileMenuVariants}
          > 
            <div className="fixed inset-0 -z-10">
              <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-blue-100/50 rounded-full blur-3xl transform translate-x-1/2 -translate-y-1/2" />
              <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-blue-200/50 rounded-full blur-3xl transform -translate-x-1/2 translate-y-1/2" />
              {/* Swirls */}
              <div className="absolute -bottom-20 left-0 text-blue-200 w-96 h-96 transform -rotate-[20deg]">
                <Swirl />
              </div>
              <div className="absolute -bottom-32 left-20 text-blue-300 w-96 h-96 transform -rotate-[60deg]">
                <Swirl />
              </div>
            </div>
            <div className="flex flex-col h-full">
              <div className="flex justify-end p-4">
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-2 rounded-md text-gray-500 hover:text-main-black hover:bg-gray-100"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              
              <div className="flex-1 flex flex-col items-center pt-8 pb-16 px-4">
                <img className="h-60 w-auto mb-12" src={logo} alt="Logo" />
                
                <div className="w-full max-w-xs space-y-2">
                  <MobileMenuItem to="/home" onClick={() => setIsMobileMenuOpen(false)}>
                    Home
                  </MobileMenuItem>
                  
                  {/* Mobile About Menu */}
                  <div>
                    <button
                      onClick={() => setIsMobileAboutOpen(!isMobileAboutOpen)}
                      className="w-full flex items-center justify-center text-2xl font-medium text-main-black py-4 hover:text-blue-600 transition-colors"
                    >
                      Over ons
                      <ChevronDownIcon 
                        className={`ml-2 h-5 w-5 transform transition-transform ${isMobileAboutOpen ? 'rotate-180' : ''}`} 
                      />
                    </button>
                    
                    <AnimatePresence>
                      {isMobileAboutOpen && (
                        <motion.div
                          initial="closed"
                          animate="open"
                          exit="closed"
                          variants={mobileSubMenuVariants}
                          className="overflow-hidden"
                        >
                          <div className="py-2 space-y-2">
                            {aboutItems.map((item) => (
                              <Link
                                key={item.path}
                                to={item.path}
                                onClick={() => setIsMobileMenuOpen(false)}
                                className="block text-xl text-gray-600 py-2 text-center hover:text-blue-500 transition-colors"
                              >
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <MobileMenuItem to="/team" onClick={() => setIsMobileMenuOpen(false)}>
                    Het team
                  </MobileMenuItem>
                  
                  <MobileMenuItem to="/contact" onClick={() => setIsMobileMenuOpen(false)}>
                    Contact
                  </MobileMenuItem>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Header;
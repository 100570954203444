// pages/about/Vision.tsx
import React from 'react';
import Header from '@/components/ui/header';
import ExpandableSection from '@/components/ui/expendable-section';

import image from '@/images/bos.jpeg';

const Vision = () => {
  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-6">Visie</h1>
        <p className='font-semibold'>
          Binnen onze praktijk die is gespecialiseerd in de behandeling van trauma, verlies en angst- en dwangklachten, kunnen we alle zorg en aandacht richten op deze vraagstukken.          
        </p> 
        <div className='flex flex-col md:flex-row gap-x-4 items-center'>
          <div className='flex-1 m-auto aspect-square items-center flex'>
            <img src={image} alt='team' className='rounded-lg ' />
          </div>
          <p className="flex-1 text-lg text-gray-700 mb-12"> 
              Onze visie is dat mensen van jong tot oud een enorm vermogen tot herstel hebben. We zijn er ook van overtuigd dat bij herstel van trauma, verlies en angst – en dwangklachten, Verbinding centraal staat.          
            <br/> 
              Voor sommigen zijn de omstandigheden zo, dat er in de vroege kindertijd nauwelijks ruimte is voor verbinding, bij anderen raakt het vermogen om in verbinding te staan, verloren in de strijd tegen de angst, de pijn of wanhoop. Er kunnen patronen ontstaan van aanvallen of terugtrekken en het verlies van contact.
            <br/>
            Wanneer je weer in verbinding komt met jezelf en waar mogelijk in verbinding met jouw naasten (jouw netwerk), kan een basis worden gelegd om nieuwe netwerken in het brein te ontwikkelen en je veerkracht te versterken. Je kunt (weer) leren om patronen te doorbreken en manieren vinden om met stress en triggers om te gaan. Ook de verbinding binnen de werkrelatie tussen cliënt en behandelaar zien wij als heel belangrijk in onze werkwijze.
          </p>
        </div>
        {/* <div className="space-y-4">
          <ExpandableSection title="Werkwijze"> 
            <br/>
            <p className="text-lg text-gray-700 mb-12">
              Wij bieden specialistische hulp voor Trauma, Verlies en Angst- en dwangklachten door individueel én systemisch te werken. We brengen daarbij goed in kaart wat er speelt bij jou en/of jouw naasten. Hierbij doen we wat nodig is om tot een goed behandeladvies te komen (geen onnodige diagnostiek). We delen onze visie met jou en komen met jou samen tot een behandelplan dat bij je past. In overleg starten we daarna direct de behandeling. Tussentijds zijn er evaluaties, stellen we de behandeling waar nodig soms bij en we ronden in overleg de behandeling (weer) af.
            </p>
          </ExpandableSection>
          
          <ExpandableSection title="Waarom wij..">
            <p className='text-gray-700 font-bold'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus ex a enim posuere volutpat. Aliquam at nisi leo. Sed pellentesque placerat interdum.
            </p>
            <br/>
            <p className="text-lg text-gray-700 mb-12">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus ex a enim posuere volutpat. Aliquam at nisi leo. Sed pellentesque placerat interdum. Suspendisse a purus eget ligula tincidunt efficitur dignissim in elit. Cras venenatis lectus sit amet ante egestas sollicitudin. Quisque tellus nunc, porttitor vel quam in, congue lacinia sapien. Phasellus maximus viverra metus, a vehicula arcu aliquam a. Curabitur vitae diam lacus. Nam non leo diam. Quisque id odio molestie, lacinia nibh in, accumsan tortor. Sed pulvinar fermentum interdum. Nullam metus justo, consectetur sed scelerisque ut, aliquam at orci. Vivamus dictum tempus ligula, in cursus lorem maximus ac. Ut sit amet blandit quam. Phasellus sed finibus nulla, ut placerat justo. Phasellus in porta erat, non volutpat sapien.
            <br/><br/>
              Vestibulum eget erat arcu. Vestibulum vehicula id purus eleifend accumsan. Maecenas nec laoreet mauris, id lobortis justo. Integer a congue risus, in porttitor lorem. Vivamus viverra odio risus, at sollicitudin ligula dictum ut. Nunc luctus, nunc quis fermentum fringilla, nisi eros scelerisque ex, ac pretium augue nunc at neque. Aliquam ullamcorper erat vitae scelerisque mollis. Duis ac nisl sed orci tristique tristique vitae sed turpis. Nam eu laoreet elit. Suspendisse nec porttitor tortor, sit amet semper velit. Sed elementum lorem vitae fringilla pretium. Nulla ornare arcu eu placerat euismod. Donec nec auctor augue. Maecenas suscipit, nibh id tincidunt dictum, ex ipsum scelerisque odio, vitae sagittis urna sapien vulputate metus. Curabitur vitae ex nec sem pharetra varius.
            <br/><br/>
              Sed ornare diam eros, fringilla egestas ante tristique eu. Ut cursus nisl et velit consequat, at blandit ex hendrerit. Suspendisse eget ullamcorper nisl, vitae sagittis nisi. Etiam vel ex et neque condimentum ultricies. Etiam tristique neque in orci tempor cursus. Aliquam in sodales tortor, eu pharetra arcu. Nulla eu felis velit. Morbi ut nunc non elit pellentesque commodo quis id ex.
            </p>
          </ExpandableSection>
        </div> */}
      </div>
    </div>
  );
};

export default Vision;
// pages/about/Vision.tsx
import React from 'react';
import Header from '@/components/ui/header';

import image from '@/images/book_table.jpg';

const Expertise = () => {
  return (
    <div className="min-h-screen">
      <Header />
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white/70 rounded-xl">
        <h1 className="text-4xl font-bold text-blue-900 mb-6">Onze expertise</h1>
        <p className='text-gray-700 font-bold'>
          Om optimale zorg te bieden, kiezen we om ons werk specifiek te richten op een afgebakende cluster aan hulpvragen; trauma, verlies en angst- en dwangklachten.
        </p>
        <br/>
        <p className="text-lg text-gray-700 mb-12">
        We beschikken over ruime ervaring en kennis en volgen alle ontwikkelingen in het werkveld om de zorg te blijven optimaliseren. Zo zetten we evidenced based methodes in zoals bijvoorbeeld EMDR, schematherapie, (Kid-)NET, schrijftherapie (bv Wright Junior). Ook gebruiken we elementen van nieuwe aandachtsgebieden/ontwikkelingen in de GGZ zoals onder meer de aandacht voor mindfulness en ademhaling.
        </p>
        <div className='w-full h-fit'>
          <img src={image} alt='team' className='rounded-lg h-1/3 object-cover object-right' />
        </div>
      </div>
    </div>
  );
};

export default Expertise;